import logo from "../../assets/images/nsn.png";

const Footer = () => {
	return (
		<footer>
			<p>
				Developed by:
				<a href="https://nsn.sa/" target="_blank" rel="noreferrer">
					<img src={logo} alt="nsn" />
				</a>
				. All Rights Reserved.
			</p>
		</footer>
	);
};

export default Footer;
